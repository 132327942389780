@font-face {
    font-family: "ApothekeFontCalibLight";
    font-weight: 400, 500;
    src: url("./Fonts/apostar.ttf") format("truetype");
}
@font-face {
    font-family: "ApothekeFontCalibLight";
    font-weight: 600, 700;
    src: url("./Fonts/apostar-bold.ttf") format("truetype");
}
